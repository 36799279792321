<template>
  <div class="row">
    <!-- <div class="col-12">
      <c-table
        ref="tableRisk"
        title="위험등록부 목록"
        tableId="riskRegister01"
        :columns="gridRisk.columns"
        :data="riskReduce.riskbooks"
        :merge="gridRisk.merge"
        :gridHeight="setheight"
      >
        <template v-slot:customArea="{ props }">
          <template v-if="props.row['sopImprovementIds'] && props.row['ibmStepNames']">
            <q-chip
              v-for="(item, index) in $comm.ibmTagItems(props.row, colorItems)"
              :key="index"
              :color="item.color"
              :clickable="true"
              text-color="white"
              style="margin-bottom:4px !important;"
              @click="linkClick(item, props.row)">
              {{item.title}}
              <c-assess-tooltip 
                :ibmTooltip="item.ibmTooltip"
                :ibmClassCd="item.ibmClassCd"
              />
            </q-chip>
          </template>
          <template v-else>
          </template>
        </template>
      </c-table>
    </div> -->
    <div class="col-12">
      <c-table
        ref="tableImpr"
        title="개선 목록"
        tableId="impr01"
        :columns="gridImpr.columns"
        :data="riskReduce.imprs"
        :merge="gridImpr.merge"
        :gridHeight="setheight"
        @linkClick="linkClick"
      >
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name==='riskHazardPicture'">
            <q-btn 
              unelevated round dense
              size="7px"
              color="primary" 
              icon="wallpaper"
              @click.stop="openHazardPicture(props)" />
          </template>
          <template v-else-if="col.name==='picture'">
            <q-btn 
              unelevated round dense
              size="7px"
              color="primary" 
              icon="wallpaper"
              @click.stop="openImprPicture(props)" />
          </template>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'fm-register-TL',
  props: {
    param: {
      type: Object,
      default: function() {
        return {
          ramRiskAssessmentPlanId: '',
          ramStepCd: '',
        }
      },
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      riskReduce: {
        imprs: [],
        riskbooks: [],
      },
      gridRisk: {
        merge: [
          { index: 0, colName: 'processName' },
          { index: 1, colName: 'processJob' },
          { index: 2, colName: 'ram4mRiskHazardClassGroup' },
        ],
        columns: [
          {
            name: 'processName',
            field: 'processName',
            label: '공정',
            align: 'left',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'jobName',
            field: 'jobName',
            label: '작업',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'ram4mRiskHazardClassName',
            field: 'ram4mRiskHazardClassName',
            label: '평가구분(4M)',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'riskHazardPicture',
            field: 'riskHazardPicture',
            label: '사진',
            align: 'center',
            style: 'width:40px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'riskHazardName',
            field: 'riskHazardName',
            label: '유해위험요인',
            align: 'left',
            style: 'width:170px',
            sortable: false,
          },
          {
            name: 'damageTargetName',
            field: 'damageTargetName',
            label: '피해대상',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'damageTypeName',
            field: 'damageTypeName',
            label: '재해형태',
            align: 'left',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'existingRiskManagementActivities',
            field: 'existingRiskManagementActivities',
            label: '현재안전조치',
            align: 'left',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'before',
            field: 'before',
            label: '개선전',
            align: 'center',
            child: [
              {
                name: 'beforeFrequency',
                field: 'beforeFrequency',
                label: '빈도',
                align: 'center',
                style: 'width:50px',
                sortable: false
              },
              {
                name: 'beforeStrength',
                field: 'beforeStrength',
                label: '강도',
                align: 'center',
                style: 'width:50px',
                sortable: false
              },
              {
                name: 'beforeRisk',
                field: 'beforeRisk',
                label: '위험도',
                align: 'center',
                style: 'width:70px',
                sortable: false,
              },
            ]
          },
          {
            name: 'addingRiskManagementActivities',
            field: 'addingRiskManagementActivities',
            label: '추가 리스크관리 계획',
            align: 'left',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'after',
            field: 'after',
            label: '개선후',
            align: 'center',
            child: [
              {
                name: 'afterFrequency',
                field: 'afterFrequency',
                label: '빈도',
                align: 'center',
                style: 'width:50px',
                sortable: false
              },
              {
                name: 'afterStrength',
                field: 'afterStrength',
                label: '강도',
                align: 'center',
                style: 'width:50px',
                sortable: false
              },
              {
                name: 'afterRisk',
                field: 'afterRisk',
                label: '위험도',
                align: 'center',
                style: 'width:70px',
                sortable: false,
              },
            ]
          },
          {
            name: 'assessDate',
            field: 'assessDate',
            label: '평가일',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'assessUserName',
            field: 'assessUserName',
            label: '평가자',
            align: 'center',
            userId: 'assessUserId',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'impr',
            field: 'impr',
            label: '개선진행',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                label: '개선번호/개선진행상태',
                align: 'center',
                style: 'width:150px',
                type: 'custom',
                sortable: false
              },
            ]
          },
        ],
        height: '500px'
      },
      gridImpr: {
        merge: [
          { index: 0, colName: 'processName' },
          { index: 1, colName: 'groupMdmSopId' },
          { index: 2, colName: 'ram4mRiskHazardClassGroup' },
          { index: 3, colName: 'ram4mAssessScenarioId' },
          { index: 4, colName: 'ram4mAssessScenarioId' },
          { index: 5, colName: 'ram4mAssessScenarioId' },
          { index: 6, colName: 'ram4mAssessScenarioId' },
          { index: 7, colName: 'ram4mAssessScenarioId' },
          { index: 8, colName: 'ram4mAssessScenarioId' },
          { index: 9, colName: 'ram4mAssessScenarioId' },
          { index: 10, colName: 'ram4mAssessScenarioId' },
        ],
        columns: [
          {
            name: 'processName',
            field: 'processName',
            label: '공정',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'jobName',
            field: 'jobName',
            label: '작업',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'ram4mRiskHazardClassName',
            field: 'ram4mRiskHazardClassName',
            label: '평가구분(4M)',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'riskHazardPicture',
            field: 'riskHazardPicture',
            label: '사진',
            align: 'center',
            style: 'width:40px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'riskHazardName',
            field: 'riskHazardName',
            label: '유해위험요인',
            align: 'left',
            style: 'width:170px',
            sortable: false,
          },
          {
            name: 'damageTargetName',
            field: 'damageTargetName',
            label: '피해대상',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'damageTypeName',
            field: 'damageTypeName',
            label: '재해형태',
            align: 'left',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'existingRiskManagementActivities',
            field: 'existingRiskManagementActivities',
            label: '현재안전조치',
            align: 'left',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'picture',
            field: 'picture',
            label: '개선<br/>전/후<br/>사진',
            align: 'center',
            type: 'custom',
            style: 'width:70px',
            sortable: false,
          },
          {
            name: 'improveFlagName',
            field: 'improveFlagName',
            label: '개선<br/>진행<br/>여부',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'addingRiskManagementActivities',
            field: 'addingRiskManagementActivities',
            label: '추가 리스크관리 계획',
            align: 'left',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            type: 'link',
            style: 'width:300px',
            sortable: true,
          },
          {
            name: 'stepFlagName',
            field: 'stepFlagName',
            label: '진행상태',
            align: 'center',
            style: 'width:100px',
            sortable: false
          },
          {
            name: 'actionCompleteDate',
            field: 'actionCompleteDate',
            label: '조치일',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ],
        data: [],
        height: '500px'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      requestImprRow: null,
      imprData: null,
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    setheight() {
      let pxRemove = this.height.replace('px', '');
      return this.height && this.height.indexOf('px') > -1 && !isNaN(pxRemove) ? (Number(pxRemove) - 20) + 'px' : '500px'
    }
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.listUrl = selectConfig.ram.fm.riskReduce.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId
      }
      this.$http.request((_result) => {
        this.$_.extend(this.riskReduce, _result.data);
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.imprData = row;
      this.popupOptions.title = '개선';
      this.popupOptions.param = {
        ram4mAssessImprId: row.ram4mAssessImprId,
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/4m/risk/4mImprRiskReduceDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        this.$_.extend(this.imprData, result.col1)
        this.$refs['tableImpr'].$refs['compo-table'].resetVirtualScroll();
      }
    },
    openHazardPicture(props) {
      this.popupOptions.title = '유해위험요인 사진';
      this.popupOptions.param = {
        ramRiskHazardId: props.row.ramRiskHazardId,
        disabled: true
      };
      this.popupOptions.target = () => import(`${'@/pages/common/hazard/riskHazardPicture.vue'}`);
      this.popupOptions.width = '30%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeHazardPicturePopup;
    },
    closeHazardPicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    openImprPicture(props) {
      this.popupOptions.title = '개선 사진';
      this.popupOptions.param = {
        ram4mAssessScenarioId: props.row.ram4mAssessScenarioId,
        ram4mAssessImprIds: props.row.ram4mAssessImprIds,
        disabled: true
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/4m/action/4mScenarioImprPicture.vue'}`);
      this.popupOptions.width = '30%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeScenarioImprPicturePopup;
    },
    closeScenarioImprPicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>